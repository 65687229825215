import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ServerStateDto} from "../interfaces/server-state.dto";
import {environment} from "../../environments/environment";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable()
export class ServerStateService {

  ngOnInit() {
    // this.checkServerState();
  }

  constructor(private http: HttpClient) {
  }

  checkServerState() {
    let address = environment.serverBaseUrl + environment.pathServerState;
    console.log('Call server state: ' + address);
    return this.http.get<ServerStateDto>(address).pipe(
      catchError(this.handleError<ServerStateDto>('getserverState'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
