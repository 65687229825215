import {Component} from '@angular/core';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor() {
    if(!environment.production) {
      console.log("=========================================================");
      console.log(" Welcome to homecontrol ui");
      console.log("=========================================================");
      console.log(" Parameters")
      console.log(" - Base server address:  " + environment.serverBaseUrl);
      console.log(" - Server test address:  " + environment.restUrlServerTest);
      console.log("=========================================================");
    } else {
      console.log("=========================================================");
      console.log(" Welcome to homecontrol ui");
      console.log("=========================================================");
      console.log(" Youre on production. No parameters... sorry")
      console.log("=========================================================");
    }
  }

  title = 'sidenavbar';
}
