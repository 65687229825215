import {Component, OnDestroy} from '@angular/core';
import {MediaChange, MediaObserver} from '@angular/flex-layout';
import {Router} from '@angular/router';
import {Subscription, timer} from 'rxjs';
import {filter, map} from 'rxjs/operators';

import {NavItem} from './ui/model/nav-item';
import {menu} from './ui/model/menu';
import {ServerStateService} from "../../service/server-state.service";
import {environment} from "../../../environments/environment";
import {ServerStateDto} from "../../interfaces/server-state.dto";

@Component({
  selector: 'app-side-main-nav',
  templateUrl: './side-main-nav.component.html',
  styleUrls: ['./side-main-nav.component.scss'],
  providers: [ServerStateService]
})
export class SideMainNavComponent implements OnDestroy {

  public opened = true;
  private mediaWatcher: Subscription;
  public menu: NavItem[] = menu;
  public serverStateData: ServerStateDto | undefined;

  isServerOnline: boolean = false;
  timerSubscription: Subscription | undefined;

  constructor(
    private media: MediaObserver,
    private router: Router,
    private serverState: ServerStateService
  ) {
    this.mediaWatcher = this.media.asObservable().pipe(
      filter((changes: MediaChange[]) => changes.length > 0),
      map((changes: MediaChange[]) => changes[0])
    )
      .subscribe((mediaChange: MediaChange) => {
        this.handleMediaChange(mediaChange);
      });
  }

  ngOnInit(): void {
    this.checkServerState();
  }

  checkServerState() {
    this.timerSubscription = timer(0, environment.serverStateInterval).pipe(
      map(() => {
        this.serverState.checkServerState().//
          subscribe(data => {
            this.serverStateData = data as unknown as ServerStateDto;
            if (this.serverStateData.isAlive != undefined) {
              this.isServerOnline = true;
              console.log('Received server state: ' + this.serverStateData.message + ' / ' + this.isServerOnline);
            } else {
              this.isServerOnline = false;
              console.log('Received server state: false');
            }
          });
      })
    ).subscribe();
  }

  private handleMediaChange(mediaChange: MediaChange): void {
    this.opened = !this.media.isActive('lt-md');
  }

  ngOnDestroy(): void {
    this.mediaWatcher.unsubscribe();
    // @ts-ignore
    this.timerSubscription.unsubscribe();
  }

  signout() {
    this.router.navigate(['/signin'])
  }
}
