<mat-toolbar color="primary">
  <button mat-icon-button (click)="sidenav.toggle()" class="menu-button" fxHide fxShow.lt-md>
    <mat-icon>menu</mat-icon>
  </button>
  <!--  <span [ngClass]="{ 'hidden': opened ? false : true }">HomeControl V2</span>-->
  <span>HomeControl V2</span>
  <span>
  </span>
  <span class="head-menu"></span>

  <div class="row">
    <div *ngIf="isServerOnline">
<!--      <button mat-button   style="width: 15px" (click)="signout()">-->
      <button mat-button   style="width: 15px">
        <mat-icon>check_circle_outline</mat-icon>
      </button>
    </div>
    <div *ngIf="!isServerOnline">
      <!--      <button mat-button   style="width: 15px" (click)="signout()">-->
      <button mat-button   style="width: 15px">
        <mat-icon>highlight_off</mat-icon>
      </button>
    </div>
  </div>
  <div class="row">
    <button mat-button (click)="signout()">
      <mat-icon>logout</mat-icon>
      Sign Out
    </button>
  </div>

</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #sidenav [mode]="opened === false ? 'over' : 'side'" [opened]="opened === true">
    <mat-nav-list>
      <app-menu-list-item *ngFor="let item of menu" [item]="item"></app-menu-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


