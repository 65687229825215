import { NavItem } from './nav-item';

export let menu: NavItem[] = [
  {
    displayName: 'Overview',
    iconName: 'home',
    route: 'overview'
  },
  {
    displayName: 'Weather',
    iconName: 'flare',
    route: 'weather'
  },
  {
    displayName: 'Sensors',
    iconName: 'explore',
    route: 'sensors'
  }
];
