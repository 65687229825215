export const environment = {
  production: true,

  // DELETE WHEN DONE: test api urls
  restUrlServerTest: 'http://hc-server.theproxy.home/user/test',

  // REST
  serverBaseUrl: 'http://hc-server.theproxy.home',
  pathHealthCheck: '/actuator/health',
  pathOpenWeather: '/ui/weather',
  pathServerState: '/ui/server/state',

  pathUserLogon: '/user/login',

  pathSensorSimpleActualData: '/data/unit/simple/states',

  // TIMER
  serverStateInterval: 60000,
  simpleUnitActualDataIntervall: 15000
};
